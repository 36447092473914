import React from "react";
import { graphql } from "gatsby";

import Helmet from "react-helmet";

import siteConfig from "../../data/SiteConfig";

import Page from "../components/Layout/Page";
import {
  FixedWidthContainer
} from "../components/Layout/ContentContainers";

const PERFORMER_TYPE_SLUG_MAP = {
    band: "bands",
    wrestler: "fest-wrestling",
    podcast: "podcasts",
    comedian: "comedy"
  };
class InvalidatorPage extends React.Component {
  constructor(props) {
    super(props);

    const performers = this.props.data.allFestPerformers.edges;

    this.state = {
      performers
    };

  }
  getMetaUrl() {
    return (
      `${siteConfig.siteUrl}${this.props.location.pathname}` ||
      siteConfig.siteUrl
    );
  }
  getMetaTitle() {
    const pageTitle = `Invalidator | ${siteConfig.siteTitle}`;

    return pageTitle;
  }
  render() {
    return (
      <Page title="Bands" showTitle={false}>
        <Helmet title={this.getMetaTitle()}>
          <meta property="og:url" content={this.getMetaUrl()} />
          <meta property="og:title" content={this.getMetaTitle()} />
        </Helmet>

        <FixedWidthContainer>
            <p><a target="_blank" href="https://cards-dev.twitter.com/validator">https://cards-dev.twitter.com/validator</a></p>
            <p><a target="_blank" href="https://developers.facebook.com/tools/debug/sharing/batch/">https://developers.facebook.com/tools/debug/sharing/batch/</a></p>

            <p>
                https://thefestfl.com{` `}<br />
                https://thefestfl.com/news{` `}<br />
                https://thefestfl.com/tickets{` `}<br />
                https://thefestfl.com/hotels{` `}<br />
                https://thefestfl.com/schedule{` `}<br />
                https://thefestfl.com/bands{` `}<br />
                https://thefestfl.com/comedy{` `}<br />
                https://thefestfl.com/fest-wrestling{` `}<br />
                https://thefestfl.com/registration{` `}<br />
                https://thefestfl.com/venues{` `}<br />
                https://thefestfl.com/flea-market{` `}<br />
                https://thefestfl.com/app{` `}<br />
                https://thefestfl.com/volunteer{` `}<br />
                https://thefestfl.com/local-eats{` `}<br />
                https://thefestfl.com/visit-gainesville{` `}<br />
                https://thefestfl.com/faq{` `}<br />
                https://thefestfl.com/contact{` `}<br />
                https://thefestfl.com/fest-photography{` `}<br />
                https://thefestfl.com/social-impact{` `}<br />
                https://thefestfl.com/history{` `}<br />
           {this.state.performers.map(performer => (
               <>https://thefestfl.com/{PERFORMER_TYPE_SLUG_MAP[performer.node.acf.type]}/{performer.node.slug}{` `}<br /></>
           ))}
           </p>
        </FixedWidthContainer>
      </Page>
    );
  }
}

export default InvalidatorPage;


/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query InvalidatorPageQuery {
    allFestPerformers {
      edges {
        node {
          id
          slug
          acf {
              type
          }
        }
      }
    }
  }
`;
